import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          myName: "Marco Signoretto",
          header: {
            portfolio: "Portfolio",
            home: "Home",
            blog: "Blog",
            about: "About me",
            guides: "Guides and Tutorials",
            privacy: "Privacy"
          },
          comingSoon: {
            title: "Coming soon",
            intro:
              "This section is under construction it will be available soon",
          },
          thisIsMe: "This is me",
          websiteIntro:
            "Welcome to my website! Here, you'll discover a wealth of information about me, including my background, passions, articles, guides, and details about the projects I'm currently working on or have completed in the past.",
          discoverNow: "Discover Now",
          aboutMe: {
            title: "About Myself",
            description: `I am a Senior Android Engineer with attention to detail. I firmly believe that well-designed, testable and maintainable code is crucial to deliver reliable and exceptional user experiences over time.
            <1/>
            What sets me apart is my aversion to repetitive tasks. I thrive on automation and efficiency, always seeking ways to streamline and simplify processes.
            <1/>
            From a young age, I have been captivated by the interaction between hardware and the real world. The concept of a robot sensing its surrounding environment and taking action continues to fascinate and inspire me to this day.
            <1/>
            Each day, I challenge myself to expand my skills and push beyond my comfort zone. This relentless pursuit of growth and exploration keeps me motivated and perpetually curious.`,
            publicRepos: "Public repositories",
            contributions: "GitHub contributions in the last year",
            publishedApps: "Personal published apps",
          },
          skills: { title: "Programming Languages Levels" },
          interests: {
            title: "My interests",
            intro: `I believe that cultivating a broad understanding of different concepts and technologies not only expands our thinking in diverse ways but also enables us to draw connections and solutions from one field to another.`,
            mobileAppsTitle: "Native mobile apps",
            mobileAppsDesc: `As an Android engineer, I develop native apps professionally. My journey in Android began in 2014 when I created my first app, Equation Solver E2G, to assist students in studying second-order equations.
            <1/>
            During my free time, I dedicate myself to personal projects where I explore new technologies, tools, and architectural approaches. These projects allow me to experiment and push the boundaries of app development and stay up to date with the industry.
            <1/>
            In recent times, I have developed a strong interest in Kotlin Multiplatform and its application for sharing business logic between Android and iOS. Exploring this technology offers exciting opportunities to optimize code sharing and enhance cross-platform compatibility.`,
            roboticsTitle: "Robotics",
            roboticsDesc: `Since a young age, I've been fascinated by robots. I started by building simple paper robots powered by DC motors at the age of 10. This passion grew during college, where I worked on a cleaning robot and later developed a self-driving prototype for my master's thesis.
            <1/>
            Despite not having a background in robotics, I took the initiative to enhance my knowledge. In 2019, I enrolled in the Modern Robotics Coursera specialization, offered by Northwestern University, to grasp the basics of motion planning and controls.
            <1/>
            My next goal is to gain a strong understanding of ROS (Robot Operating System) to further advance my robotics skills. I'm eager to leverage this knowledge for future projects and innovations.`,
            webAppsTitle: "Web apps",
            webAppsDesc: `The complexity of backend systems and the infrastructure behind DevOps has always captivated me. I am intrigued by topics such as infrastructure provisioning, system architecture, and the art of building scalable and resilient solutions that can seamlessly accommodate a growing user base.
            <1/>
            Throughout my experience, I have had the chance to work on small and medium-sized projects using Java and Kotlin (Spring). 
            <1/>
            Recently, I have also begun expanding my knowledge into frontend development.`,
          },
          footer: {
            termsAndPrivacy: "Terms and Privacy",
            contacts: "Contacts",
            sitemap: "Sitemap",
            followMe: "Follow Me",
            copyright: "This website is made by Marco Signoretto and Giulia Furlan",
          },
          portfolio: {
            fun: "Fun level",
            tags: "Tags",
            title: "Projects",
            description:
              "These are projects that I completed or that I'm working on",
            category: {
              title: "Category",
              all: "All Categories",
              mobile_apps: "Mobile Apps",
              web: "Web Apps",
              robotics: "Robotics",
            },
            languages: "Programming languages",
          },
          blog: {
            conferences: "Conferences",
            conferencesDesc: "Tech conference talks",
            articles: "Articles",
            articlesDesc: "Tech articles",
            viewMore: "View more",
            category: { talk: "Conference talk", article: "Article" },
          },
        },
      },
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
