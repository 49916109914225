import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import "./styles.css";

export const ProjectPrivacyBanner: React.FC = () => {
  const { t } = useTranslation();
  const { projectName } = useParams();

  const capitalize = (str: string | null | undefined) => {
    if (str == null) return ""
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <section className="bnr_area">
      <div className="bnr_inner d-flex align-items-center">
        <div className="overlay" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background=""></div>
        <div className="container">
          <div className="bnr_content text-center">
            <h2>{projectName}</h2>
            <div className="page_link">
              <Link to="/">{t("header.home")}</Link>
              <Link to="/portfolio">{t("header.portfolio")}</Link>
              <Link to={`/portfolio/${projectName}`}>{capitalize(projectName)}</Link>
              <Link to={`/portfolio/${projectName}/privacy`}>{t("header.privacy")}</Link>
            </div>
          </div>
        </div>
      </div>
    </section >);


};
