import { useTranslation } from "react-i18next";
import "./styles.css";

import logo from "assets/img/logo_icon.webp";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

export const HeaderMenu: React.FC = () => {
  const { t } = useTranslation();

  const headerRef = useRef<HTMLDivElement>(null);
  const [navBarFixed, setNavBarFixed] = useState(false);
  const [navBarCollapsed, setNavBarCollapsed] = useState(true);

  useEffect(() => {
    const nav_height = headerRef.current!!.clientHeight
    const onScroll = () => {
      const scroll = window.scrollY
      if (scroll >= nav_height + 50) {
        setNavBarFixed(true)
      } else {
        setNavBarFixed(false)
      }
    }
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [headerRef]);

  return (
    <header className={`header_area ${(navBarFixed) ? "navbar_fixed" : ""}`}>
      <div className="main_menu" id="mainNav" ref={headerRef}>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container box_1620">
            <Link className="navbar-brand logo_h" to="/">
              <img src={logo} className="logo_icon" alt="logo" />
              <span className="logo_name">msignoretto</span>
              <span className="logo_com">.com</span>
            </Link>
            <button
              className={`navbar-toggler `}
              type="button"
              aria-expanded={navBarCollapsed ? "false" : "true"}
              aria-label="Toggle navigation"
              onClick={() => {
                setNavBarCollapsed(!navBarCollapsed)
              }}
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <div
              className={`${navBarCollapsed ? "" : "expand"} navbar-collapse offset`}
              id="navbarSupportedContent"
            >
              <ul className="nav navbar-nav menu_nav ml-auto">
                <li className="nav-item active">
                  <Link to="/" className="nav-link">
                    {t("header.home")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/portfolio" className="nav-link">
                    {t("header.portfolio")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/blog" className="nav-link">
                    {t("header.blog")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};
