import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Project } from "./Project.type"
import "./styles.css";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { ShimmerThumbnail } from "component/shimmer/Image";

export const PortfolioProject: React.FC = () => {

  const categories: string[] = ["all", "mobile_apps", "robotics", "web"]

  const { t } = useTranslation();

  const [activeSelection, setActiveSelection] = useState<string>(categories[0]);
  const { isLoading, data } = useQuery<Project[]>(`/api/projects?lang=en`, () =>
    fetch(`${process.env.REACT_APP_API_END_POINT}/api/projects?lang=en`)  // TODO fix language
      .then((res) => res.json())
  );

  const [projects, setProjects] = useState<Project[]>([]);
  useEffect(() => {
    if (data) {
      setProjects(data)
    }
  }, [data]);

  const projectFilter = (selection: string) => {
    if (data) {
      setProjects(
        data.filter(project => {
          return selection === "all" ? projects : project.category === selection
        })
      )
      setActiveSelection(selection)
    }
  }

  return (
    <section id="portfolio" className="projects_area p_120">
      <div className="container">
        <div className="main_title">
          <h2>{t("portfolio.title")}</h2>
          <p>{t("portfolio.description")}</p>
        </div>
        <div className="projects_fillter">
          <ul className="filter list">
            {categories.map((category, index) => (
              <li key={category} className={activeSelection === category ? "active" : ""}>
                {console.log(category)}
                <button className="btn shadow-none" onClick={() => projectFilter(category)} disabled={!data}>{t(`portfolio.category.${category}`)}</button>
              </li>
            ))}
          </ul>
        </div>
        <div className="projects_inner row">
          {(() => {
            if (isLoading) {
              return Array(6).fill(1).map(() =>
                <div className={`col-lg-4 col-sm-6`}>
                  <ShimmerThumbnail className="projects_item" height={339} />
                </div>
              )
            } else {
              return projects.map((project) => (
                <div key={project.uuid} className={`col-lg-4 col-sm-6 ${project.category}`}>
                  <Link to={`/portfolio/${project.uuid}`}>
                    <div className="projects_item">
                      <img
                        className="img-fluid"
                        src={project.previewImage}
                        alt=""
                      />
                      <div className="projects_text">
                        <h4>{project.title}</h4>
                        <p>
                          {t(`portfolio.category.${project.category}`)}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            }
          })()}

        </div>
      </div>
    </section>
  );
};
