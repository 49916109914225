import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./styles.css";

export const PortfolioBanner: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="bnr_area">
      <div className="bnr_inner d-flex align-items-center">
        <div
          className="overlay"
          data-stellar-ratio="0.9"
          data-stellar-vertical-offset="0"
          data-background=""
        ></div>
        <div className="container">
          <div className="bnr_content text-center">
            <h2>{t("header.portfolio")}</h2>
            <div className="page_link">
              <Link to="/">{t("header.home")}</Link>
              <Link to="/portfolio">{t("header.portfolio")}</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
