import { useParams } from "react-router-dom";
import { MarkdownContent } from "component/Common/MarkdownContent";
import { useQuery } from "react-query";
import ShimmerText from "component/shimmer/Text";
import ShimmerTitle from "component/shimmer/Title";
import { PrivacyPolicy } from "../PrivacyPolicy.type";

export const ProjectPrivacyContent: React.FC = () => {
    const { projectName, platform } = useParams();

    const { isLoading, data } = useQuery<PrivacyPolicy[]>(`/api/project/${projectName}/privacy?lang=en`, () =>
        fetch(`${process.env.REACT_APP_API_END_POINT}/api/project/${projectName}/privacy?lang=en`)  // TODO fix language
            .then((res) => res.json())
    );

    return (
        <section className="portfolio_details_area p_120">
            <div className="container">
                <div className="portfolio_details_inner" >
                    {(() => {
                        if (isLoading) {
                            return <>
                                <ShimmerTitle lineWidthPercentage={80} line={1} />
                                <ShimmerText line={32} />
                            </>
                        } else if (data) {
                            return <>
                                {(() => {
                                    const policy = data.find((policy) => policy.type.toLocaleLowerCase() === platform?.toLocaleLowerCase())
                                    if (policy) {
                                        return <>
                                            <span >
                                                <MarkdownContent markdown={policy.content} />
                                            </span>
                                        </>
                                    }
                                })()}
                            </>
                        }
                    })()}
                </div>
            </div>
        </section>
    );
};
