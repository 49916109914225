import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from 'react'

import "./styles.css";
import { BlogPost } from "../BlogPost.type";

export const BlogContentBanner: React.FC = () => {
  const { t } = useTranslation();
  const { blogPostName } = useParams();
  const [blogPost, setBlogPost] = useState<BlogPost>();
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_END_POINT}/api/post/${blogPostName}`)  // TODO fix language
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        setBlogPost(res)
      })
    console.log('Component mounted');
    return () => {
      console.log('Component will be unmount')
    }
  }, [blogPostName]);

  return (
    <section className="bnr_area">
      <div className="bnr_inner d-flex align-items-center">
        <div className="overlay" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background=""></div>
        <div className="container">
          <div className="bnr_content text-center">
            <h2>{blogPost?.title}</h2>
            <div className="page_link">
              <Link to="/">{t("header.home")}</Link>
              <Link to="/blog">{t("header.blog")}</Link>
              <Link to={`/blog/${blogPostName}`}>{blogPost?.title}</Link>
            </div>
          </div>
        </div>
      </div>
    </section >);
};
