import { BlogContent } from "./BlogContent";
import { BlogContentBanner } from "./Banner";

export const BlogDetails: React.FC = () => {

  return (
    <div>
      <BlogContentBanner />
      <BlogContent />
    </div>
  );
};
