import React from "react";
import classNames from "classnames";
import "./title.scss";

interface ShimmerTitleProps {
  line?: number;
  gap?: 10 | 15 | 20 | 30;
  lineHeight?: number
  lineWidthPercentage?: number
  variant?: "primary" | "secondary";
  className?: string;
}

const ShimmerTitle: React.FC<ShimmerTitleProps> = ({ line = 2, gap = 10, variant = "primary", className = "", lineHeight = 24, lineWidthPercentage = 100 }: ShimmerTitleProps) => {
  const renderLines = () => {
    const title_lines = [];

    for (let index = 0; index < line; index++) {
      title_lines.push(
        <div className="shimmer shimmer-title-line" key={index} style={{ height: `${lineHeight}px`, width: `${lineWidthPercentage}%` }} />
      );
    }

    return title_lines;
  };

  return (
    <div
      className={classNames({
        grid: true,
        "shimmer-title": variant === "primary",
        "shimmer-title--secondary": variant === "secondary",
        [`grid-gap-${gap}`]: gap,
        [className]: className,
      })}
    >
      {renderLines()}
    </div>
  );
};

ShimmerTitle.defaultProps = {
  line: 2,
  gap: 10,
  variant: "primary",
};

export default ShimmerTitle;
