import { Trans, useTranslation } from "react-i18next";
import "./style.css";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { GithubStats } from "./GithubStats.type";





// const obj = document.getElementById("value");
// animateValue(100, 0, 5000);

export const AboutMe: React.FC = () => {
  const duration = 3000
  const contributionsDefault = 1500
  const publicReposDefault = 8
  const { t } = useTranslation();

  const [startTimestamp] = useState<number>(Date.now());

  const { data } = useQuery<GithubStats>('/api/contributions', () =>
    fetch(`${process.env.REACT_APP_API_END_POINT}/api/contributions`)  // TODO fix language
      .then((res) => res.json())
      .then((res) => {
        return {
          contribution: res.contributions,
          publicRepos: res.publicRepos
        } as GithubStats
      })
  )

  const [progress, setProgress] = useState<number>(0)
  const [githubStats, setGithubStats] = useState<GithubStats>({ contribution: 0, publicRepos: 0 } as GithubStats)

  useEffect(() => {
    const timestamp = Date.now()
    const end = (data) ? data : { contribution: contributionsDefault, publicRepos: publicReposDefault } as GithubStats
    const progress = Math.min((timestamp - ((startTimestamp) ? startTimestamp : 0)) / duration, 1);
    const step = (timestamp: number) => {
      setProgress(progress + timestamp)
    };
    if (progress < 1) {
      window.requestAnimationFrame(step)
      setGithubStats({
        contribution: Math.floor(progress * end.contribution),
        publicRepos: Math.floor(progress * end.publicRepos)
      });
    } else {
      setGithubStats({ contribution: end.contribution, publicRepos: end.publicRepos })
    }
  }, [progress, data, startTimestamp])

  return (
    <section id="about-me" className="welcome_area p_120">
      <div className="container">
        <div className="row welcome_inner">
          <div className="col-lg-6">
            <div className="welcome_text">
              <h4>{t("aboutMe.title")}</h4>
              <p>
                <Trans i18nKey="aboutMe.description" components={{ 1: <br /> }} />
              </p>
              <div className="row">
                <div className="col-md-4">
                  <div className="wel_item">
                    <span style={{ color: "#8490ff" }}>
                      <i className="lnr lnr-book"></i>
                    </span>
                    <h4>{githubStats.contribution}</h4>
                    <p>{t("aboutMe.contributions")}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="wel_item">
                    <span style={{ color: "#74a4ff" }}>
                      <i className="lnr lnr-database"></i>
                    </span>

                    <h4>{githubStats.publicRepos}</h4>
                    <p>{t("aboutMe.publicRepos")}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="wel_item">
                    <span style={{ color: "#62bdfc" }}>
                      <i className="lnr lnr-cloud"></i>
                    </span>

                    <h4>4</h4>
                    <p>{t("aboutMe.publishedApps")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="tools_expert">
              <h3>{t("skills.title")}</h3>
              <div className="skill_main">
                <div className="skill_item">
                  <h4>
                    Kotlin <span className="counter">95</span>%
                  </h4>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow={95}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "95%" }}
                    ></div>
                  </div>
                </div>
                <div className="skill_item">
                  <h4>
                    Java <span className="counter">90</span>%
                  </h4>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow={90}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "90%" }}
                    ></div>
                  </div>
                </div>
                <div className="skill_item">
                  <h4>
                    C++ <span className="counter">50</span>%
                  </h4>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "50%" }}
                    ></div>
                  </div>
                </div>
                <div className="skill_item">
                  <h4>
                    Python <span className="counter">40</span>%
                  </h4>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow={40}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "40%" }}
                    ></div>
                  </div>
                </div>
                <div className="skill_item">
                  <h4>
                    Swift <span className="counter">30</span>%
                  </h4>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow={30}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "30%" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
