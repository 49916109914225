import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './style.css';

export const Footer: React.FC = () => {
  const { t } = useTranslation();

  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <footer className='footer_area p_120'>
      <div className='container'>
        <div className='row footer_inner'>
          <div className='col-lg-5 col-sm-6'>
            <aside className='f_widget news_widget'>
              <div className='f_title'>
                <h3>{t('footer.contacts')}</h3>
              </div>
              <p>
                Email:{' '}
                <a href='mailto:marco.signoretto.dev@gmail.com'>
                  marco.signoretto.dev@gmail.com
                </a>
              </p>
            </aside>
          </div>
          <div className='col-lg-5 col-sm-6'>
            <aside className='f_widget news_widget'>
              <div className='f_title'>
                <h3>{t('footer.sitemap')}</h3>
              </div>
              <span>
                - <Link to='/'>{t('header.home')}</Link>
              </span>
              <br />
              <span>
                - <Link to='/portfolio'>{t('header.portfolio')}</Link>
              </span>
              <br />
              <span>
                - <Link to='/blog'>{t('header.blog')}</Link>
              </span>
            </aside>
          </div>
          <div className='col-lg-2'>
            <aside className='f_widget social_widget'>
              <div className='f_title'>
                <h3>{t('footer.followMe')}</h3>
              </div>
              <ul className='list'>
                <li>
                  <a
                    href='https://github.com/MarcoSignoretto'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='fa fa-github'></i>
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.linkedin.com/in/marco-signoretto-b66b22b7/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='fa fa-linkedin'></i>
                  </a>
                </li>
                <li>
                  <a
                    href='https://twitter.com/signorettomarco'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='fa fa-twitter'></i>
                  </a>
                </li>
              </ul>
            </aside>
          </div>
          <div className='col-lg-10 col-sm-12'>
            <div className='f_title'>
              <p>Copyright &copy; {currentYear} All rights reserved</p>
            </div>
            <p>
              <span>
                This template is made with{' '}
                <i className='fa fa-heart-o' aria-hidden='true'></i> by{' '}
                <a href='https://colorlib.com' target='_blank' rel='noreferrer'>
                  Colorlib
                </a>{' '}
                and Edited by Marco Signoretto and Giulia Furlan
              </span>
              <br />
              <span>{t('footer.copyright')}</span>
            </p>
          </div>
          <div className='col-lg-2 col-sm-12'>
            <div className='f_title'></div>
            <a href='https://www.websitepolicies.com/policies/view/UfjqoA5g'>
              {t('footer.termsAndPrivacy')}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
