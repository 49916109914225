import { PortfolioBanner } from "./Banner";
import { PortfolioProject } from "./Projects";

export const Portfolio: React.FC = () => {
  return (
    <div>
      <PortfolioBanner />
      <PortfolioProject />
    </div>
  );
};
