import React from "react";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools';
import "./vendor/linericon/style.css"
import "./index.css";
import "./responsive.css";

Bugsnag.start({
  apiKey: '16e915990a89f43ea2456b94be497617',
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')!!.createErrorBoundary(React)


const firebaseConfig = {
  apiKey: "AIzaSyBkiHn2OPEglLFNXMYs3KYhm5E2cZuhltY",
  authDomain: "msignoretto-fe.firebaseapp.com",
  projectId: "msignoretto-fe",
  storageBucket: "msignoretto-fe.appspot.com",
  messagingSenderId: "182391672527",
  appId: "1:182391672527:web:fe6a0c6e4cdb405c3df1ba",
  measurementId: "G-7N41VQS5NL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const queryClient = new QueryClient();
logEvent(analytics, `msignoretto_loaded`);

ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </BrowserRouter>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
