import { HeaderMenu } from "./component/HeaderMenu";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./i18n.ts";
import { Footer } from "component/Footer";
import { Navigate, Route, Routes } from "react-router-dom";
import { Home } from "component/Home";
import { Portfolio } from "component/Portfolio";
import { Blog } from "component/Blog";
import { ProjectDetails } from "component/ProjectDetails";
import { ProjectPrivacy } from "component/ProjectPrivacy";
import { BlogDetails } from "component/BlogDetails";
import "./styles/styles.scss";
import ScrollToTop from "component/Common/ScrollToTop";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <HeaderMenu />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="portfolio" element={<Portfolio />} />
        <Route path="portfolio/:projectName" element={<ProjectDetails />} />
        <Route path="portfolio/:projectName/privacy/:platform" element={<ProjectPrivacy />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:blogPostName" element={<BlogDetails />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
