import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BlogPost } from "../BlogPost.type";
import { MarkdownContent } from "component/Common/MarkdownContent";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useQuery } from "react-query";
import ShimmerTitle from "component/shimmer/Title";
import ShimmerText from "component/shimmer/Text";

export const BlogContent: React.FC = () => {
  const { blogPostName } = useParams();

  const { isLoading, data } = useQuery<BlogPost>(`/api/post/${blogPostName}`, () =>
    fetch(`${process.env.REACT_APP_API_END_POINT}/api/post/${blogPostName}`)  // TODO fix language
      .then((res) => res.json())
  )

  useEffect(() => {
    if (data) {
      const analytics = getAnalytics();
      logEvent(analytics, `view_blog_${data!!.uuid}`);
      console.log(`view_blog_${data!!.uuid}`)
    }
  }, [data])


  return (
    <section className="p_120">
      <div className="container">
        <div className="blog_details" >
          <span>
            {(() => {
              if (isLoading) {
                return <>
                  <ShimmerTitle lineHeight={36} lineWidthPercentage={70} line={1} />
                  <ShimmerText />
                  <ShimmerTitle line={1} lineWidthPercentage={50} />
                  <ShimmerText line={2} />
                  <ShimmerText line={3} />
                  <ShimmerText line={2} />
                  <ShimmerText line={5} />
                </>
              } else {
                return data && <MarkdownContent markdown={data.content} />
              }
            })()}
          </span>
        </div>
      </div>
    </section >
  );
};
