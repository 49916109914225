import React from "react";
import classNames from "classnames";

interface ShimmerThumbnailProps {
  height?: number;
  width?: number;
  center?: boolean;
  fitOnFrame?: boolean;
  rounded?: boolean;
  className?: string;
}

const ShimmerThumbnail: React.FC<ShimmerThumbnailProps> = ({
  height = 250,
  width,
  center = false,
  className = "",
  fitOnFrame = false,
  rounded = false,
}: ShimmerThumbnailProps) => {
  const style: { height?: string, width?: string } = {};
  if (height) style.height = `${height}px`;
  if (width) style.width = `${width}px`;

  return (
    <div
      className={
        classNames({
          "h-100": fitOnFrame,
          "text-center ": center,
        })
      }
    >
      <div
        className={
          classNames({
            "h-100": fitOnFrame,
            "shimmer shimmer-thumbnail": true,
            "border-rounded": rounded,
            [className]: className,
          })
        }
        style={style}
      ></div>
    </div>
  );
};

export default ShimmerThumbnail;
