import { AboutMe } from "./AboutMe";
import { HomeBanner } from "./Banner";
import { Interest } from "./Interest";

export const Home: React.FC = () => {
  return (
    <div>
      <HomeBanner />
      <AboutMe />
      <Interest />
    </div>
  );
};
