import { ProjectPrivacyContent } from "./Content";
import { ProjectPrivacyBanner } from "./Banner";

export const ProjectPrivacy: React.FC = () => {

  return (
    <div>
      <ProjectPrivacyBanner />
      <ProjectPrivacyContent />
    </div>
  );
};
