import React from "react";
import classNames from "classnames";
import "./text.scss";

interface ShimmerTextProps{
  line?: number;
  gap?: 10 | 15 | 20 | 30;
  align?: "left" | "right";
  className?: string;
}

const ShimmerText: React.FC<ShimmerTextProps> = ({ line = 5, gap = 15, align = "left", className = "" }: ShimmerTextProps) => {
  const renderLines = () => {
    const text_lines = [];

    for (let index = 0; index < line; index++) {
      text_lines.push(
        <div className={`shimmer shimmer-text-line ${(align === "left") ? "" : "right"}`} key={index} />
      );
    }

    return text_lines;
  };

  return (
    <div
      className={classNames({
        "grid shimmer-text": true,
        [`grid-gap-${gap}`]: gap,
        [className]: className,
      })}
    >
      {renderLines()}
    </div>
  );
};

export default ShimmerText;
