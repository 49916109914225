import { ProjectContent } from "./ProjectContent";
import { ProjectBanner } from "./Banner";

export const ProjectDetails: React.FC = () => {

  return (
    <div>
      <ProjectBanner />
      <ProjectContent />
    </div>
  );
};
