import React from "react";
import classNames from "classnames";
import "./button.scss";

interface ShimmerButtonProps {
  size?: "lg" | "md" | "sm";
}

const ShimmerButton: React.FC<ShimmerButtonProps> = ({ size = "md" }) => (
  <div
    className={classNames({
      "shimmer shimmer-button": true,
      [`shimmer-button--${size}`]: true,
    })}
  />
);


export default ShimmerButton;
