import { Trans, useTranslation } from "react-i18next";
import "./style.css";

export const Interest: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section id="interests" className="feature_area p_120">
      <div className="container">
        <div className="main_title">
          <h2>{t("interests.title")}</h2>
          <p>{t("interests.intro")}</p>
        </div>
        <div className="feature_inner row">
          <div className="col-lg-4 col-md-6">
            <div className="feature_item">
              <i
                className="icon fa fa-android fa-4x"
                style={{ color: "lightgray" }}
              ></i>
              <h4>{t("interests.mobileAppsTitle")}</h4>
              <p>
                <Trans i18nKey="interests.mobileAppsDesc" components={{ 1: <br /> }} />
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="feature_item">
              <i
                className="icon fa fa-industry fa-4x"
                style={{ color: "lightgray" }}
              ></i>
              <h4>{t("interests.roboticsTitle")}</h4>
              <p>
                <Trans i18nKey="interests.roboticsDesc" components={{ 1: <br /> }} />
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="feature_item">
              <i
                className="icon fa fa-cloud fa-4x"
                style={{ color: "lightgray" }}
              ></i>
              <h4>{t("interests.webAppsTitle")}</h4>
              <p>
                <Trans i18nKey="interests.webAppsDesc" components={{ 1: <br /> }} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
