import { useTranslation } from "react-i18next";
import homeLeft from "assets/img/banner/home-left.webp";
import "./style.css";

export const HomeBanner: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <section className="home_bnr_area">
        <div className="bnr_inner">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="home_left_img">
                  <img src={homeLeft} alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="bnr_content">
                  <h5>{t("thisIsMe")}</h5>
                  <h2>{t("myName")}</h2>
                  <p>{t("websiteIntro")}</p>
                  <a
                    href="https://github.com/MarcoSignoretto"
                    target="_blank"
                    rel="noreferrer"
                    className="social"
                  >
                    <i className="fa fa-github fa-lg"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/in/marco-signoretto-b66b22b7/"
                    target="_blank"
                    rel="noreferrer"
                    className="social"
                  >
                    <i className="fa fa-linkedin fa-lg"></i>
                  </a>
                  <a
                    href="https://twitter.com/signorettomarco"
                    target="_blank"
                    rel="noreferrer"
                    className="social"
                  >
                    <i className="fa fa-twitter fa-lg"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
