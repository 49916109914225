import ShimmerButton from "component/shimmer/Button";
import ShimmerText from "component/shimmer/Text";
import ShimmerTitle from "component/shimmer/Title";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { BlogPost } from "./BlogPost.type";
import "./styles.css";


export const BlogPosts: React.FC = () => {
    const { t } = useTranslation();

    const { isLoading, error, data } = useQuery<BlogPost[]>('/api/posts', () =>
        fetch(`${process.env.REACT_APP_API_END_POINT}/api/posts`)  // TODO fix language
            .then((res) => res.json())
    )

    return (
        <section className="blog_area">
            <div id="blog" className="container">
                {(() => {
                    if (isLoading) {

                        return Array(3).fill(1).map(() =>
                            <div className="col-lg-12">
                                <div className="blog_left_sidebar">
                                    <article className="row blog_item">
                                        <div className="col-md-3">
                                            <div className="blog_info text-right">
                                                <ShimmerText line={3} align={"right"} />
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="blog_post">
                                                <div className="blog_details">
                                                    <ShimmerTitle />
                                                    <ShimmerText line={3} gap={10} />
                                                    <ShimmerButton />
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        )
                    } else if (error) {
                        return <p>Something went wrong</p> // TODO improve this with proper error with reload
                    } else {
                        return data?.map((blogPost) => (
                            <div key={blogPost.uuid} className="row">
                                <div className="col-lg-12">
                                    <div className="blog_left_sidebar">
                                        <article className="row blog_item">
                                            <div className="col-md-3">
                                                <div className="blog_info text-right">
                                                    <ul className="blog_meta list">
                                                        <li><span> {blogPost.tags}<i className="lnr lnr-tag"></i></span></li>
                                                        <li><span> {blogPost.authors}<i className="lnr lnr-user"></i></span></li>
                                                        <li><span> {blogPost.date}<i className="lnr lnr-calendar-full"></i></span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="blog_post">
                                                    <div className="blog_details">
                                                        <h4 >{t(`blog.category.${blogPost.category}`)}</h4>
                                                        <Link to={`/blog/${blogPost.uuid}`}><h2 > {blogPost.title}</h2></Link>
                                                        <p > {blogPost.description}</p>
                                                        <Link to={`/blog/${blogPost.uuid}`} className="blog_btn" >View More</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                })()}
            </div>
        </section>
    );
};

