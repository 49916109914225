import { BlogBanner } from "./Banner";
import { BlogPosts } from "./BlogPosts";

export const Blog: React.FC = () => {
  return (
    <div>
      <BlogBanner />
      <BlogPosts />
    </div>
  );
};
