import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";
import rehypeHighlight from "rehype-highlight";


export const MarkdownContent: React.FC<{ markdown: string }> = ({ markdown }) => {
    return (
        <ReactMarkdown rehypePlugins={[rehypeRaw, rehypeHighlight]} >
            {markdown}
        </ReactMarkdown>
    );
};


