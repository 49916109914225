import { useTranslation } from "react-i18next";
import { Project } from "component/Portfolio/Projects/Project.type";
import { useParams } from "react-router-dom";
import { MarkdownContent } from "component/Common/MarkdownContent";
import { useQuery } from "react-query";
import ShimmerText from "component/shimmer/Text";
import { ShimmerThumbnail } from "component/shimmer/Image";
import ShimmerTitle from "component/shimmer/Title";

export const ProjectContent: React.FC = () => {
    const { t } = useTranslation();
    const { projectName } = useParams();

    const { isLoading, data } = useQuery<Project>(`/api/project/${projectName}?lang=en`, () =>
        fetch(`${process.env.REACT_APP_API_END_POINT}/api/project/${projectName}?lang=en`)  // TODO fix language
            .then((res) => res.json())
    );

    return (
        <section className="portfolio_details_area p_120">
            <div className="container">
                <div className="portfolio_details_inner" >
                    {(() => {
                        if (isLoading) {
                            return <>
                                <div className="row" >
                                    <div className="col-md-6">
                                        <div className="left_img">
                                            <ShimmerThumbnail />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="portfolio_right_text">
                                            <ShimmerTitle lineWidthPercentage={80} line={1} />
                                            <ShimmerText line={2} />
                                            <ul className="list">
                                                <li>
                                                    <ShimmerText line={1} />
                                                </li>
                                                <li>
                                                    <ShimmerText line={1} />
                                                </li>
                                                <li>
                                                    <ShimmerText line={1} />
                                                </li>
                                                <li>
                                                    <ShimmerText line={2} />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <span >
                                    <ShimmerText line={4} />
                                    <ShimmerText line={3} />
                                    <ShimmerText line={5} />
                                    <ShimmerText line={6} />
                                </span>
                            </>
                        } else if (data) {
                            return <>
                                <div className="row" >
                                    <div className="col-md-6">
                                        <div className="left_img">
                                            <img className="project_details_img" src={data.images[0]} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="portfolio_right_text">
                                            <h4 > {data.uuid}</h4>
                                            <p > {data.shortDescription}</p>
                                            <ul className="list">
                                                <li>
                                                    <span > {t("portfolio.fun")}</span>:
                                                    {
                                                        Array(data.funLevel).fill(1).map((value, index) => ( // TODO clean it up
                                                            <i key={index} className="fa fa-star"></i>
                                                        ))
                                                    }
                                                </li>
                                                <li>
                                                    <span > {t(`portfolio.category.title`)}</span>:
                                                    <span > {t(`portfolio.category.${data.category}`)}</span>
                                                </li>
                                                <li>
                                                    <span > {t("portfolio.languages")}</span>:
                                                    {
                                                        data.programmingLanguages.map((language) => (
                                                            <span key={language} className="badge badge-pill badge-primary tag"> {language}</span>
                                                        ))
                                                    }
                                                </li>
                                                <li>
                                                    <span > {t("portfolio.tags")}</span>:<br />
                                                    {
                                                        data.tags.map((tag) => (
                                                            <span key={tag} className="badge badge-secondary tag">{tag}</span>
                                                        ))
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <span >
                                    <MarkdownContent markdown={data.content} />
                                </span>
                            </>
                        }
                    })()}
                </div>
            </div>
        </section>
    );
};
